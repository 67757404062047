<template>
  <div class="teammember-teaser">
    <div class="imagewrapper">
      <Image
        v-if="data.image && data.image.responsiveImage"
        class="image"
        :data="data.image.responsiveImage"
        object-fit="cover"
      />
    </div>
    <div class="contentwrapper">
      <p v-if="data.firstname && data.lastname" class="name copy-regular-bold">
        {{ data.firstname }} {{ data.lastname }}
      </p>
      <p v-if="data.role" class="role copy-regular">{{ data.role }}</p>
      <div class="links">
        <a v-if="data.eMail" :href="`mailto:${data.eMail}`" class="button-link -secondary email">E-Mail</a>
        <button
          v-if="data.description && !isEmptyDocument(data.description)"
          class="button-link -secondary more"
          @click="toggleOverlay()"
        >
          {{ moreAboutText }}
        </button>
      </div>
    </div>
    <TeammemberDetailOverlay
      :data="data"
      class="detailoverlay"
      :class="{ '-open': isDetailOverlayOpen }"
      @close-overlay="toggleOverlay"
    />
  </div>
</template>

<script setup lang="ts">
import { Image } from 'vue-datocms';
import { isEmptyDocument } from 'datocms-structured-text-utils';
import type { TeamMemberFragment } from '#gql';
import TeammemberDetailOverlay from '~/components/content-modules/components/TeammemberDetailOverlay.vue';

defineProps<{
  data: TeamMemberFragment;
}>();

const isDetailOverlayOpen = ref<boolean>(false);

const { locale } = useI18n();

function toggleOverlay(open = !isDetailOverlayOpen.value) {
  isDetailOverlayOpen.value = open;

  toggleLock(isDetailOverlayOpen.value);
}

function toggleLock(lock: boolean) {
  if (lock) {
    document.body.style.overflowY = 'clip';
  } else {
    document.body.style.overflowY = 'unset';
  }
}

const moreAboutText = computed(function () {
  return locale.value === 'de' ? 'Mehr zur Person' : 'More about the person';
});
</script>

<style scoped lang="scss">
.teammember-teaser {
  padding: var(--spacing-sm) 0;

  > .imagewrapper {
    margin-bottom: var(--spacing-3xs);

    > .image {
      width: 100%;
      aspect-ratio: 1 / 1;
    }
  }

  > .contentwrapper {
    > .name {
      margin-bottom: var(--spacing-4xs);
    }

    > .links {
      > .email,
      > .more {
        margin-top: var(--spacing-2xs);
      }

      > .more {
        display: block;
      }
    }
  }

  > .detailoverlay {
    display: none;

    &.-open {
      display: block;
    }
  }
}
</style>
