<template>
  <div class="filter-container">
    <h2 v-if="title" class="title text-xl">{{ title }}</h2>
    <div class="items filter copy-regular">
      <button class="item" :class="{ '-active': !model }" @click="model = undefined">
        {{ t('allText') }}
      </button>
      <button
        v-for="item in items"
        :key="item.id"
        class="item"
        :class="{ '-active': item.id === model?.id }"
        @click="model = item"
      >
        {{ item.name }}
        <SvgClose class="icon" @click.prevent.stop="model = undefined" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import SvgClose from '~/components/content-modules/svgs/SvgClose.vue';

interface Item {
  id: any;
  name?: string | null;
}

defineProps<{
  title?: string | null;
  items: Item[];
}>();

const model = defineModel<Item>();

const { t } = useI18n();
</script>

<i18n lang="yaml">
de:
  allText: Alle
en:
  allText: All
</i18n>

<style scoped lang="scss">
.filter-container {
  @media (--xs) {
    grid-column: 1 / -1;
  }

  @media (--sm) {
    grid-column: 2 / 24;
  }

  @media (--md), (--lg) {
    grid-column: 5 / 21;
  }

  @media (--md), (--lg) {
    padding: var(--spacing-sm) 0;
  }

  > .title {
    margin-bottom: var(--spacing-2xs);
  }

  > .items {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;

    > .item {
      background-color: var(--color-main-blue-light);
      padding: var(--spacing-4xs) 0.875rem;
      border-radius: 6.25rem;
      cursor: pointer;

      &:not(.-active) {
        > .icon {
          display: none;
        }
      }

      &.-active {
        background-color: var(--color-main-blue);
        color: var(--color-white);
        display: flex;
        align-items: center;
        gap: var(--spacing-4xs);
      }
    }
  }
}
</style>
