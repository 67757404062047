export function dateFormatter(dateString: string, locale: string): string | null {
  if (!dateString) {
    return null;
  }

  const date = new Date(dateString);

  const germanDate = date.toLocaleString('de-DE', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const englishDate = date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return locale === 'de' ? germanDate : englishDate;
}