<template>
  <div class="content-teacher-department">
    <FilterControl v-model="selectedDepartment" :items="sortedDepartments" :title="data.title" />

    <div v-for="department in filteredDepartments" :key="department.id" class="detail-container">
      <h3 v-if="department.name" class="departmentname">{{ department.name }}</h3>
      <div v-for="teacher in filteredTeachers(department)" :key="teacher.id" class="teachers">
        <TeammemberTeaser :data="teacher" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ContentTeacherDepartmentFragment } from '#gql';
import TeammemberTeaser from '~/components/content-modules/components/TeammemberTeaser.vue';
import FilterControl from '~/components/content-modules/components/FilterControl.vue';

const props = defineProps<{
  data: ContentTeacherDepartmentFragment;
}>();

const selectedDepartment = ref<ContentTeacherDepartmentFragment['departments'][number]>();

const sortedDepartments = computed(function () {
  return [...props.data.departments].sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
});

const filteredDepartments = computed(function () {
  return sortedDepartments.value.filter((d) => !selectedDepartment.value || selectedDepartment.value.id === d.id);
});

function filteredTeachers(department: { id: string; name?: string | null }) {
  return props.data.teachers.filter((teacher) => teacher.departments.some((d) => d.id === department.id));
}
</script>

<style scoped lang="scss">
.content-teacher-department {
  @include grid-layout();

  & {
    padding: var(--site-inner-padding);
    width: 100%;
    max-width: var(--site-max-width);
  }

  > .detail-container {
    grid-column: 1 / -1;
    padding: var(--spacing-sm) 0;
    display: grid;
    gap: var(--spacing-xs);
    width: 100%;

    @media (--xs) {
      display: flex;
      flex-direction: column;
      gap: unset;
    }

    @media (--sm) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: unset;
    }

    @media (--md), (--lg) {
      grid-template-columns: repeat(4, 1fr);
    }

    > .departmentname {
      grid-column: 1 / -1;

      @media (--md), (--lg) {
        margin-bottom: -2rem;
      }
    }
  }
}
</style>
