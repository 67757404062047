<template>
  <div class="content-department">
    <div class="inner">
      <FilterControl v-model="selectedDepartment" :items="sortedDepartments" :title="data.title" />

      <div v-for="department in filteredDepartments" :key="department.id" class="detail-container">
        <h3 v-if="department.name" class="name">{{ department.name }}</h3>
        <ContentImage v-if="department.image" :data="department.image" class="image" />
        <StructuredText v-if="department.description" :data="department.description" class="structured-text text-lg" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { StructuredText } from 'vue-datocms';
import type { ContentDepartmentFragment } from '#gql';
import ContentImage from '~/components/content-modules/ContentImage.vue';
import FilterControl from '~/components/content-modules/components/FilterControl.vue';

const props = defineProps<{
  data: ContentDepartmentFragment;
}>();

const selectedDepartment = ref<ContentDepartmentFragment['departments'][number]>();

const sortedDepartments = computed(function () {
  return [...props.data.departments].sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
});

const filteredDepartments = computed(function () {
  return sortedDepartments.value.filter((d) => !selectedDepartment.value || selectedDepartment.value.id === d.id);
});
</script>

<style scoped lang="scss">
.content-department {
  @include grid-layout();

  & {
    padding: var(--site-inner-padding);
    width: 100%;
    max-width: var(--site-max-width);
  }

  > .inner {
    @media (--xs) {
      grid-column: 1 / -1;
    }

    @media (--sm) {
      grid-column: 2 / 24;
    }

    @media (--md), (--lg) {
      grid-column: 5 / 21;
    }

    > .detail-container {
      padding: var(--spacing-sm) 0;
      display: grid;
      gap: var(--spacing-3xs);
      max-width: 900px;

      > .image {
        display: unset;
        padding: unset;
      }
    }
  }
}
</style>
