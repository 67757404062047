<template>
  <div class="figure-item" :class="{ '-open': isOpen }">
    <div class="header" @click="toggleOpen()">
      <p class="title">{{ item.title }}</p>
      <SvgMinus v-if="isOpen" />
      <SvgPlus v-else />
    </div>
    <div class="main">
      <StructuredText v-if="item.text" class="structured-text content text-lg" :data="item.text" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { StructuredText } from 'vue-datocms';
import SvgMinus from '~/components/content-modules/svgs/SvgMinus.vue';
import SvgPlus from '~/components/content-modules/svgs/SvgPlus.vue';

defineProps<{
  item: {
    id: string;
    text: {
      value: string;
    };
    title: string;
  };
}>();

const isOpen = ref<boolean>(false);

function toggleOpen(open = !isOpen.value) {
  isOpen.value = open;
}
</script>

<style scoped lang="scss">
.figure-item {
  position: relative;

  &.-open {
    > .main {
      grid-template-rows: 1fr;
      margin: var(--spacing-2xs) 0 var(--spacing-md);
    }
  }

  > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    cursor: pointer;
  }

  > .main {
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    transition:
      grid-template-rows 300ms ease-in-out,
      margin-top 300ms ease-in-out;

    > .content {
      min-height: 0;
    }
  }
}
</style>
