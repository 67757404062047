import ContentText from '~/components/content-modules/ContentText.vue';
import ContentRegistrationprocess from '~/components/content-modules/ContentRegistrationprocess.vue';
import ContentAccordion from '~/components/content-modules/ContentAccordion.vue';
import ContentDownload from '~/components/content-modules/ContentDownload.vue';
import ContentImage from '~/components/content-modules/ContentImage.vue';
import ContentImageText from '~/components/content-modules/ContentImageText.vue';
import ContentNews from '~/components/content-modules/ContentNews.vue';
import ContentTeammember from '~/components/content-modules/ContentTeammember.vue';
import ContentTestimonial from '~/components/content-modules/ContentTestimonial.vue';
import ContentImageSlider from '~/components/content-modules/ContentImageSlider.vue';
import CtaContact from '~/components/content-modules/CtaContact.vue';
import ContentTeaser from '~/components/content-modules/ContentTeaser.vue';
import CtaStoerer from "~/components/content-modules/CtaStoerer.vue";
import ContentDepartment from "~/components/content-modules/ContentDepartment.vue";
import ContentTeam from "~/components/content-modules/ContentTeam.vue";
import ContentVideo from '~/components/content-modules/ContentVideo.vue';
import ContentNewsSlider from '~/components/content-modules/ContentNewsSlider.vue';
import CtaDefault from '~/components/content-modules/CtaDefault.vue';
import ContentTeacherDepartment from '~/components/content-modules/ContentTeacherDepartment.vue';

export const ContentModules = {
  ContentTextRecord: ContentText,
  ContentVideoRecord: ContentVideo,
  ContentRegistrationprocessRecord: ContentRegistrationprocess,
  ContentAccordionRecord: ContentAccordion,
  ContentDownloadRecord: ContentDownload,
  ContentImageRecord: ContentImage,
  ContentImagetextRecord: ContentImageText,
  ContentNewsRecord: ContentNews,
  ContentNewssliderRecord: ContentNewsSlider,
  ContentTeammemberRecord: ContentTeammember,
  ContentTestimonialRecord: ContentTestimonial,
  ContentImagesliderRecord: ContentImageSlider,
  CtaContactRecord: CtaContact,
  ContentTeaserRecord: ContentTeaser,
  CtaStoererRecord: CtaStoerer,
  CtaDefaultRecord: CtaDefault,
  ContentDepartmentRecord: ContentDepartment,
  ContentTeacherDepartmentRecord: ContentTeacherDepartment,
  ContentTeamRecord: ContentTeam,
};
