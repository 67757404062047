<template>
  <div class="content-team">
    <FilterControl v-model="selectedTeam" :items="sortedTeams" :title="data.title" />

    <div v-for="team in filteredTeams" :key="team.id" class="detail-container">
      <h3 v-if="team.name" class="teamname">{{ team.name }}</h3>
      <div v-for="teammember in filteredTeamMembers(team)" :key="teammember.id" class="teammembers">
        <TeammemberTeaser :data="teammember" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ContentTeamFragment } from '#gql';
import TeammemberTeaser from '~/components/content-modules/components/TeammemberTeaser.vue';
import FilterControl from '~/components/content-modules/components/FilterControl.vue';

const props = defineProps<{
  data: ContentTeamFragment;
}>();

const selectedTeam = ref<ContentTeamFragment['teams'][number]>();

const sortedTeams = computed(function () {
  return [...props.data.teams].sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
});

const filteredTeams = computed(function () {
  return sortedTeams.value.filter((d) => !selectedTeam.value || selectedTeam.value.id === d.id);
});

function filteredTeamMembers(team: { id: string; name?: string | null }) {
  return props.data.teamMembers.filter((teammember) => teammember.teams.some((d) => d.name === team.name));
}
</script>

<style scoped lang="scss">
.content-team {
  @include grid-layout();

  & {
    padding: var(--site-inner-padding);
    width: 100%;
    max-width: var(--site-max-width);
  }

  > .detail-container {
    grid-column: 1 / -1;
    padding: var(--spacing-sm) 0;
    display: grid;
    gap: var(--spacing-xs);
    width: 100%;

    @media (--xs) {
      display: flex;
      flex-direction: column;
      gap: unset;
    }

    @media (--sm) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: unset;
    }

    @media (--md), (--lg) {
      grid-template-columns: repeat(4, 1fr);
    }

    > .teamname {
      grid-column: 1 / -1;

      @media (--md), (--lg) {
        margin-bottom: -2rem;
      }
    }
  }
}
</style>
