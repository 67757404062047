import { toHead, type ToMetaTagsType } from 'vue-datocms';

export function useSeo(
  datoMetaTags: { attributes?: any; content?: string | null | undefined; tag: string }[],
  opts: {
    customCanonicalUrl?: string;
    customTitleTemplate?: string;
  } = {},
) {
  const datoHead = toHead(datoMetaTags as ToMetaTagsType);
  const i18nHead = useLocaleHead({
    addDirAttribute: true,
    identifierAttribute: 'id',
    addSeoAttributes: true,
  });

  if (opts.customCanonicalUrl) {
    // REPLACE THE CANONICAL URL determined by i18n
    const foundLinkItem = i18nHead.value.link?.find((l: any) => l.rel === 'canonical');
    foundLinkItem.href = opts.customCanonicalUrl;
  }

  useHead({
    htmlAttrs: {
      lang: i18nHead.value.htmlAttrs!.lang,
    },
    title: datoHead.title ?? '',
    titleTemplate: opts.customTitleTemplate ?? '%s | Freies Gymnasium Zürich',
    link: [...(i18nHead.value.link || []), ...datoHead.link],
    meta: [...(i18nHead.value.meta || []), ...datoHead.meta],
  });
}
