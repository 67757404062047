<template>
  <div class="news-teaser">
    <Image
      v-if="data.image && data.image.responsiveImage"
      class="image"
      :data="data.image.responsiveImage"
      object-fit="cover"
    />
    <div class="content">
      <div class="titlewrapper">
        <p v-if="data.date" class="date copy-regular">{{ formattedDate }}</p>
        <h2 v-if="data.title" class="title text-2xl">{{ data.title }}</h2>
      </div>
      <NuxtLink v-if="internalRoute" :to="internalRoute" class="button-link link -secondary"
        >{{ readMoreText }}
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Image } from 'vue-datocms';
import type { NewsTeaserItemFragment } from '#gql';
import { getRouteObject } from '~/datocms/getRouteObject';
import { dateFormatter } from '~/utils/dateFormatter';

const props = defineProps<{
  data: NewsTeaserItemFragment;
}>();

const { locale } = useI18n();
const { resolve } = useRouter();

const internalRoute = computed(() => {
  if (props.data.translatedSlug) {
    return resolve(getRouteObject(props.data.translatedSlug, 'NewsRecord', locale.value)).href;
  }

  return null;
});

const formattedDate = computed(function () {
  return dateFormatter(props.data.date, locale.value);
});

const readMoreText = computed(() => {
  return locale.value === 'de' ? 'Weiterlesen' : 'Read more';
});
</script>

<style scoped lang="scss">
.news-teaser {
  background-color: var(--color-white);
  color: var(--color-forest-dark);
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--xs), (--sm), (--md) {
    height: 25rem;
  }

  @media (--lg) {
    height: 32.75rem;
  }

  > .image {
    width: 100%;
    height: 50%;
  }

  > .content {
    padding: var(--spacing-2xs) var(--spacing-2xs) var(--spacing-xs);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    > .titlewrapper {
      > .title {
        // display max. 3 lines
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin: var(--spacing-4xs) 0 var(--spacing-2xs);
        color: var(--color-forest-dark);
      }
    }

    :deep(.link) {
      color: var(--color-forest-dark);
      border-color: var(--color-forest-dark);
    }
  }
}
</style>
