import type { RouteLocation } from 'vue-router';

export function getRouteObject(slug: string, __typename: string, locale: string) {
  const route: Partial<RouteLocation> = {
    name: '',
    params: {
      slug,
    },
    meta: {
      locale,
    },
  };

  if (slug === 'home') {
    route.name = `index___${locale}`;
    route.params = undefined;
  } else if (__typename === 'NewsRecord') {
    route.name = `news-slug___${locale}`;
  } else if (__typename === 'PageRecord') {
    route.name = `slug___${locale}`;
  }

  return route as RouteLocation;
}
