<template>
  <div class="teammember-detail-overlay">
    <div class="grid-container">
      <div class="inner">
        <button class="button-secondary button -back" @click="$emit('closeOverlay')">{{ t('backText') }}</button>
        <div class="imagewrapper">
          <Image
            v-if="data.image && data.image.responsiveImage"
            class="image"
            :data="data.image.responsiveImage"
            object-fit="cover"
          />
        </div>
        <div class="contentwrapper">
          <p v-if="data.firstname && data.lastname" class="name text-xl">{{ data.firstname }} {{ data.lastname }}</p>
          <p v-if="data.role" class="role copy-regular">{{ data.role }}</p>
          <StructuredText
            v-if="data.description"
            :data="data.description"
            class="structured-text description copy-regular"
          />
          <a v-if="data.eMail" :href="`mailto:${data.eMail}`" class="button-link -secondary email">E-Mail</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Image, StructuredText } from 'vue-datocms';
import type { TeamMemberFragment } from '#gql';

defineProps<{
  data: TeamMemberFragment;
}>();

defineEmits(['closeOverlay']);

const { t } = useI18n();
</script>

<i18n lang="yaml">
de:
  backText: Zurück
en:
  backText: Back
</i18n>

<style scoped lang="scss">
.teammember-detail-overlay {
  position: fixed;
  background: var(--color-white);
  z-index: var(--z-overlay);
  height: 100vh;
  inset: 0;
  padding: var(--spacing-xs) 0;
  overflow-y: auto;

  > .grid-container {
    @include grid-layout();

    & {
      padding: var(--site-inner-padding);
      width: 100%;
      max-width: var(--site-max-width);
      margin: auto;
      align-content: center;
    }

    @media (--md), (--lg) {
      height: 100%;
    }

    > .inner {
      @media (--xs), (--sm) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-2xs);
      }

      @media (--xs) {
        grid-column: 1 / -1;
      }

      @media (--sm) {
        grid-column: 2 / 24;
      }

      @media (--md), (--lg) {
        grid-column: 3 / 23;
        display: grid;
        grid-template-columns: subgrid;
        row-gap: var(--spacing-lg);
      }

      > .button.-back {
        grid-column: 1 / -1;
        grid-row: 1 / 2;
        justify-self: start;
      }

      > .imagewrapper {
        grid-row: 2 / 3;
        grid-column: 1 / 9;
        align-self: stretch;

        > .image {
          width: 100%;
          max-width: 24rem;
          aspect-ratio: 1 / 1;
        }
      }

      > .contentwrapper {
        grid-row: 2 / 3;
        grid-column: 9 / -1;

        > .role {
          margin-bottom: var(--spacing-2xs);
        }

        > .email {
          margin-top: var(--spacing-2xs);
        }
      }
    }
  }
}
</style>
