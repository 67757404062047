<template>
  <div class="content-teammember">
    <div class="inner">
      <h2 v-if="data.title" class="title text-2xl">{{ data.title }}</h2>
      <div class="teammemberwrapper" :class="`-grid-cols-${columnCount}`">
        <TeammemberTeaser v-for="i in data.teamMembers" :key="i.id" :data="i" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ContentTeammemberFragment } from '#gql';
import TeammemberTeaser from '~/components/content-modules/components/TeammemberTeaser.vue';

const props = defineProps<{
  data: ContentTeammemberFragment;
}>();

const columnCount = computed(() => {
  const count = props.data.teamMembers.length;
  if (count >= 4) return 4;
  if (count === 3) return 3;
  if (count <= 2) return 2;
  return count;
});
</script>

<style scoped lang="scss">
.content-teammember {
  @include grid-layout();

  & {
    padding: var(--site-inner-padding);
    width: 100%;
    max-width: var(--site-max-width);
  }

  @media (--md), (--lg) {
    margin: var(--spacing-sm) 0;
  }

  > .inner {
    @media (--xs) {
      grid-column: 1 / -1;
    }
    @media (--sm) {
      grid-column: 2 / 24;
    }
    @media (--md), (--lg) {
      grid-column: 3 / 23;
    }

    > .teammemberwrapper {
      display: grid;
      gap: var(--spacing-xs);

      @media (--xs) {
        display: flex;
        flex-direction: column;
        gap: unset;
      }

      @media (--sm), (--md) {
        grid-template-columns: repeat(2, 1fr);
        row-gap: unset;
      }

      @media (--lg) {
        &.-grid-cols-2 {
          grid-template-columns: repeat(2, 1fr);
        }

        &.-grid-cols-3 {
          grid-template-columns: repeat(3, 1fr);
        }

        &.-grid-cols-4 {
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }
  }
}
</style>
